import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
				Elite Turf
			</title>
			<meta name={"description"} content={"Ідеальна подача для кожного гравця"} />
			<meta property={"og:title"} content={"Elite Turf"} />
			<meta property={"og:description"} content={"Ідеальна подача для кожного гравця"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6672ad768e2e8e00217d19da/images/1-2.jpg?v=2024-06-19T12:06:47.309Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6672ad768e2e8e00217d19da/images/1-2.jpg?v=2024-06-19T12:06:47.309Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6672ad768e2e8e00217d19da/images/1-2.jpg?v=2024-06-19T12:06:47.309Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6672ad768e2e8e00217d19da/images/1-2.jpg?v=2024-06-19T12:06:47.309Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6672ad768e2e8e00217d19da/images/1-2.jpg?v=2024-06-19T12:06:47.309Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6672ad768e2e8e00217d19da/images/1-2.jpg?v=2024-06-19T12:06:47.309Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6672ad768e2e8e00217d19da/images/1-2.jpg?v=2024-06-19T12:06:47.309Z"} />
		</Helmet>
		<Components.Contact>
			<Override slot="link1" />
			<Override slot="link" />
		</Components.Contact>
		<Components.Nav />
		<Section padding="180px 0 180px 0" md-padding="100px 0 100px 0" quarkly-title="Content-12" background="linear-gradient(0deg,rgba(0, 0, 0, 0.5) 0%,rgba(0, 0, 0, 0.65) 100%),rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/6672ad768e2e8e00217d19da/images/1-3.jpg?v=2024-06-19T12:06:47.257Z) 50% 50% /cover repeat scroll padding-box">
			<Override
				slot="SectionContent"
				flex-direction="row"
				align-items="center"
				justify-content="center"
				md-flex-direction="column"
			/>
			<Box
				min-width="100px"
				min-height="100px"
				padding="0px 80px 0px 0px"
				width="50%"
				lg-width="70%"
				md-width="100%"
				md-padding="0px 0 0px 0px"
				md-margin="0px 0px 50px 0px"
			>
				<Text margin="0px 0px 30px 0px" font="--headline2" color="white">
					Наші контакти
				</Text>
				<Text margin="0px 0px 40px 0px" color="--light" font="--base">
					Не пропустіть можливість зіграти на найкращому футбольному майданчику. Зв’яжіться з нами зараз, щоб забронювати вільний час, дізнатися про наші послуги або запланувати екскурсію нашими об’єктами.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 90px 0" quarkly-title="Contacts-3">
			<Text
				lg-padding="0px 0px 0px 0px"
				sm-font="normal 700 36px/1.2 --fontFamily-sans"
				margin="0px 0px 18px 0px"
				font="--headline2"
				color="--darkL1"
				text-align="left"
				padding="0px 0 0px 0px"
				lg-width="100%"
				lg-margin="0px 0px 25px 0px"
			>
				Щоб дізнатися про повний спектр наших послуг і про те, як ми можемо задовольнити ваші потреби, зв’яжіться з нами.
			</Text>
			<Text
				margin="16px 40% 50px 0px"
				font="--base"
				lg-margin="0px 0 50px 0px"
				text-align="left"
				sm-margin="0px 0 35px 0px"
				color="--dark"
				lg-max-width="720px"
			>
				{" "}Незалежно від того, чи бажаєте ви орендувати поле, забронювати обладнання чи планувати захід, наша команда тут, щоб допомогти вам. Зв’яжіться з нами сьогодні, і ми допоможемо вам покращити свій ігровий досвід.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				grid-template-columns="repeat(4, 1fr)"
				grid-gap="36px 34px"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
			>
				<Box
					min-width="100px"
					min-height="100px"
					padding="0px 0px 0px 18px"
					border-width="0 0 0 2px"
					border-style="solid"
					border-color="--color-lightD2"
					width="50%"
				>
					<Text margin="0px 0px 15px 0px" font="--lead">
						Адреса
					</Text>
					<Text margin="0px 0px 0px 0px" font="--base" color="--dark">
						Кутківці, Ternopil,{" "}
						<br />
						Ternopil Oblast, 46003
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					padding="0px 0px 0px 18px"
					border-width="0 0 0 2px"
					border-style="solid"
					border-color="--color-lightD2"
					width="50%"
				>
					<Text margin="0px 0px 15px 0px" font="--lead">
						Номер телефону
					</Text>
					<Text margin="0px 0px 0px 0px" font="--base" color="--dark">
						0969714741
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Nav />
		<Components.Contact>
			<Override slot="link1">
				0969714741
			</Override>
			<Override slot="link">
				Кутківці, Ternopil, Ternopil Oblast, 46003
			</Override>
		</Components.Contact>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"66329762fdcae00021e1d75a"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});